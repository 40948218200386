"use strict";
// src/ts/Main.ts
exports.__esModule = true;
/// <reference types="jquery"/>
var application_1 = require("./application");
require("bootstrap/scss/bootstrap.scss");
require("../scss/main.scss");
jQuery(function () {
    var application = new application_1.Application();
    application.run();
});
