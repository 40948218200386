"use strict";
// src/ts/application.ts
exports.__esModule = true;
exports.Application = void 0;
require("core-js/stable/dom-collections/for-each");
require("classlist-polyfill");
var menu_1 = require("./menu");
var search_1 = require("./search");
var fancybox_1 = require("./../../shared/ts/fancybox");
var calendar_1 = require("./calendar");
var slider_1 = require("./slider");
var swiper_slider_1 = require("./swiper.slider");
var form_validation_1 = require("./form.validation");
var video_1 = require("./video");
var to_top_1 = require("./to.top");
var version_switch_1 = require("./../../shared/ts/version.switch");
var static_assets_1 = require("./static.assets");
require("jquery-parallax.js");
var Application = /** @class */ (function () {
    function Application() {
    }
    Application.prototype.run = function () {
        this.initMenu();
        this.initSearch();
        this.initFancybox();
        this.initCalendar();
        this.initFromValidation();
        this.initVideo();
        this.initSlider();
        this.initSwiperSlider();
        this.initToTop();
        this.initVersionSwitch();
        this.initStaticAssets();
    };
    Application.prototype.initMenu = function () {
        new menu_1.Menu({
            collapseContainer: '.navbar-collapse-menu',
            togglerSelector: '.navbar-toggler-menu',
            isSubmenuCollapsed: true,
            expandedMenuItemSelector: '.mobile-menu__expanded-item',
            expandedMenuContainerSelector: '.mobile-menu__expanded_container',
            submenuContainerSelector: '.mobile-submenu__container'
        }).run();
    };
    Application.prototype.initSearch = function () {
        var items = document.querySelectorAll('.header__search');
        items.forEach(function (item) {
            new search_1.Search({
                element: item,
                containerSelector: '.header__search-form'
            }).run();
        });
    };
    Application.prototype.initFancybox = function () {
        new fancybox_1.Fancybox({
            elementsSelector: '.photo__item, .zoom'
        }).run();
    };
    Application.prototype.initCalendar = function () {
        var items = document.querySelectorAll('.calendar__container');
        items.forEach(function (item) {
            new calendar_1.Calendar({
                baseLoadUrl: '/api/afisha/calendar',
                container: item,
                loadSuccessCallback: function (container) {
                    window.dispatchEvent(new Event('load'));
                }
            }).run();
        });
    };
    Application.prototype.initFromValidation = function () {
        var items = document.querySelectorAll('form');
        items.forEach(function (item) {
            new form_validation_1.FormValidation({
                element: item,
                options: {
                    unhighlight: function (element, errorClass, validClass) {
                        element.classList.remove(errorClass);
                        element.classList.add(validClass);
                    }
                }
            }).run();
        });
    };
    Application.prototype.initVideo = function () {
        var items = document.querySelectorAll('.video__container');
        items.forEach(function (item) {
            new video_1.Video({
                container: item,
                playFullButtonSelector: '.play__full_btn'
            }).run();
        });
    };
    Application.prototype.initSwiperSlider = function () {
        var sliderMain = document.querySelector('.main-slider');
        if (sliderMain !== null) {
            var slideProcess = function (swiperInstance) {
                var currentSlideIndex = swiperInstance.activeIndex, currentSlide = swiperInstance.slides[currentSlideIndex];
                if (currentSlide.classList.contains('item-video')) {
                    var video = currentSlide.querySelector('video');
                    if (video !== null) {
                        swiperInstance.autoplay.stop();
                        video.pause();
                        video.currentTime = 0;
                        video.play();
                        video.addEventListener('ended', function () {
                            swiperInstance.slideNext();
                            swiperInstance.autoplay.start();
                        });
                    }
                }
            };
            var instance = new swiper_slider_1.SwiperSlider({
                container: sliderMain,
                options: {
                    init: false,
                    lazy: {
                        loadPrevNext: true,
                    },
                    speed: 1000,
                    loop: true,
                    autoplay: {
                        delay: 7000,
                        disableOnInteraction: false,
                    },
                    navigation: {
                        nextEl: '.navigation-button-next',
                        prevEl: '.navigation-button-prev',
                    }
                }
            }).run();
            instance.on('init', slideProcess);
            instance.on('slideChange', slideProcess);
            instance.init();
        }
    };
    Application.prototype.initSlider = function () {
        var sliderMain = document.querySelector('.owl-main-slider');
        if (sliderMain !== null) {
            new slider_1.Slider({
                element: sliderMain,
                options: {
                    loop: true,
                    items: 1,
                    dots: false,
                    autoplay: true,
                    nav: true,
                    autoplayHoverPause: true,
                    autoplayTimeout: 7000,
                    smartSpeed: 1000,
                    lazyLoad: true,
                    onChanged: function () {
                    }
                }
            }).run();
        }
        var sliderShow = document.querySelector('.owl-main-show');
        if (sliderShow !== null) {
            new slider_1.Slider({
                element: sliderShow,
                options: {
                    loop: false,
                    items: 3,
                    dots: true,
                    nav: true,
                    autoplay: true,
                    responsiveClass: true,
                    autoplayHoverPause: true,
                    autoplayTimeout: 6000,
                    smartSpeed: 1000,
                    responsive: {
                        0: {
                            items: 2,
                            margin: 10
                        },
                        410: {
                            items: 3,
                            margin: 10
                        },
                        992: {
                            items: 4,
                            margin: 20
                        },
                        1600: {
                            items: 5,
                            margin: 30
                        }
                    }
                }
            }).run();
        }
        var photoSliders = document.querySelectorAll('.owl-photo-show');
        photoSliders.forEach(function (sliderContainer) {
            new slider_1.Slider({
                element: sliderContainer,
                options: {
                    loop: true,
                    items: 3,
                    dots: true,
                    nav: true,
                    autoplay: true,
                    responsiveClass: true,
                    autoplayHoverPause: true,
                    autoplayTimeout: 6000,
                    smartSpeed: 1000,
                    responsive: {
                        0: {
                            items: 2,
                            margin: 0
                        },
                        410: {
                            items: 3,
                            margin: 0
                        }
                    }
                }
            }).run();
        });
        var sliderPerformanceReviews = document.querySelector('.owl-perf-reviews');
        if (sliderPerformanceReviews !== null) {
            new slider_1.Slider({
                element: sliderPerformanceReviews,
                options: {
                    loop: true,
                    items: 3,
                    dots: true,
                    nav: true,
                    autoplay: true,
                    responsiveClass: true,
                    autoplayHoverPause: true,
                    autoplayTimeout: 6000,
                    smartSpeed: 1000,
                    responsive: {
                        0: {
                            items: 1,
                            margin: 10
                        },
                        768: {
                            items: 2,
                            margin: 40
                        },
                        992: {
                            items: 2,
                            margin: 100
                        },
                        1600: {
                            items: 2,
                            margin: 130
                        }
                    }
                }
            }).run();
        }
        var sliderPerformanceTickets = document.querySelector('.owl-page-show');
        if (sliderPerformanceTickets !== null) {
            new slider_1.Slider({
                element: sliderPerformanceTickets,
                options: {
                    loop: false,
                    items: 3,
                    dots: true,
                    nav: true,
                    autoplay: true,
                    responsiveClass: true,
                    autoplayHoverPause: true,
                    autoplayTimeout: 6000,
                    smartSpeed: 1000,
                    responsive: {
                        0: {
                            items: 2,
                            margin: 5
                        },
                        410: {
                            items: 3,
                            margin: 5
                        },
                        540: {
                            items: 4,
                            margin: 5
                        },
                        769: {
                            items: 3,
                            margin: 5
                        },
                        992: {
                            items: 4,
                            margin: 5
                        },
                        1600: {
                            items: 5,
                            margin: 5
                        }
                    }
                }
            }).run();
        }
    };
    Application.prototype.initToTop = function () {
        new to_top_1.ToTop();
    };
    Application.prototype.initVersionSwitch = function () {
        var items = document.querySelectorAll('.version-switch');
        items.forEach(function (item) {
            new version_switch_1.VersionSwitch({
                triggerElement: item
            }).run();
        });
    };
    Application.prototype.initStaticAssets = function () {
        new static_assets_1.StaticAssets().run();
    };
    return Application;
}());
exports.Application = Application;
