"use strict";
// src/ts/slider.ts
exports.__esModule = true;
exports.Slider = void 0;
/// <reference types="jquery"/>
require("owl.carousel");
var Slider = /** @class */ (function () {
    function Slider(config) {
        this.element = jQuery(config.element);
        if (config.options) {
            this.options = config.options;
        }
    }
    Slider.prototype.run = function () {
        this.element.owlCarousel(this.options);
    };
    return Slider;
}());
exports.Slider = Slider;
