"use strict";
// src/ts/video.ts
exports.__esModule = true;
exports.ToTop = void 0;
var vanilla_back_to_top_1 = require("vanilla-back-to-top");
var ease_component_1 = require("ease-component");
var ToTop = /** @class */ (function () {
    function ToTop() {
        vanilla_back_to_top_1.addBackToTop({
            diameter: 70,
            backgroundColor: 'rgb(226, 49, 49)',
            cornerOffset: 15,
            ease: ease_component_1.outQuart,
            textColor: '#fff',
            scrollDuration: 1000,
            showWhenScrollTopIs: 100,
            innerHTML: '<svg viewBox="0 0 24 24"><path d="M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z"/></svg>',
        });
    }
    return ToTop;
}());
exports.ToTop = ToTop;
