"use strict";
// src/ts/base/BootstrapCollapse.ts
exports.__esModule = true;
exports.BootstrapCollapse = void 0;
/// <reference types="jquery"/>
/// <reference types="bootstrap"/>
require("bootstrap/js/dist/collapse");
var BootstrapCollapse = /** @class */ (function () {
    function BootstrapCollapse(config) {
        this.collapseContainer = config.collapseContainer;
        this.togglerSelector = config.togglerSelector;
    }
    BootstrapCollapse.prototype.getCollapseContainer = function () {
        return this.collapseContainer;
    };
    BootstrapCollapse.prototype.run = function () {
        var _this = this;
        jQuery(this.togglerSelector).on('click', function (event) {
            event.preventDefault();
            _this.toggle();
        });
        this.trackEvents();
    };
    BootstrapCollapse.prototype.show = function () {
        jQuery(this.collapseContainer).collapse('show');
    };
    BootstrapCollapse.prototype.hide = function () {
        jQuery(this.collapseContainer).collapse('hide');
    };
    BootstrapCollapse.prototype.toggle = function () {
        jQuery(this.collapseContainer).collapse('toggle');
    };
    BootstrapCollapse.prototype.trackEvents = function () {
    };
    return BootstrapCollapse;
}());
exports.BootstrapCollapse = BootstrapCollapse;
