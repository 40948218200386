"use strict";
// src/ts/video.ts
exports.__esModule = true;
exports.Video = void 0;
require("classlist-polyfill");
var Video = /** @class */ (function () {
    function Video(config) {
        this.container = config.container;
        this.element = this.container.querySelector('video');
        if (config.playFullButtonSelector) {
            this.playFullButtonElement = this.container.querySelector(config.playFullButtonSelector);
        }
        this.fullPlayed = false;
    }
    Video.prototype.run = function () {
        this.element.play();
        this.initEventListeners();
    };
    Video.prototype.initEventListeners = function () {
        var _this = this;
        if (this.playFullButtonElement) {
            this.playFullButtonElement.addEventListener('click', function (event) { return _this.clickPlayFullButtonElementHandler(event); });
        }
    };
    Video.prototype.clickPlayFullButtonElementHandler = function (event) {
        var element = event.target;
        event.preventDefault();
        if (!this.fullPlayed) {
            this.element.muted = false;
            this.element.src = this.element.dataset.full;
            this.element.play();
            this.playFullButtonElement.classList.add('stop');
            this.playFullButtonElement.querySelector('.main-block__text').innerHTML = 'Остановить просмотр';
            this.fullPlayed = true;
        }
        else {
            this.element.muted = true;
            this.element.src = this.element.dataset.preview;
            this.element.play();
            this.playFullButtonElement.classList.remove('stop');
            this.playFullButtonElement.querySelector('.main-block__text').innerHTML = 'Смотреть трейлер';
            this.fullPlayed = false;
        }
    };
    return Video;
}());
exports.Video = Video;
