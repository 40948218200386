"use strict";
exports.__esModule = true;
exports.Search = void 0;
require("classlist-polyfill");
var Search = /** @class */ (function () {
    function Search(config) {
        this.element = config.element;
        this.containerSelector = config.containerSelector;
        this.toggleClassName = config.toggleClassName ? config.toggleClassName : 'd-block';
        this.container = this.element.querySelector(this.containerSelector);
        if (this.container.nodeName == 'FORM') {
            this.form = this.container;
        }
        else {
            this.form = this.container.querySelector('form');
        }
    }
    Search.prototype.run = function () {
        this.initEventListeners();
    };
    Search.prototype.initEventListeners = function () {
        var _this = this;
        if (this.element && this.container) {
            this.element.addEventListener('click', function (event) { return _this.clickElementHandler(event); });
            this.container.addEventListener('click', function (event) { return _this.clickContainerHandler(event); });
        }
    };
    Search.prototype.clickElementHandler = function (event) {
        var element = event.target;
        event.preventDefault();
        if (this.container.classList.contains(this.toggleClassName)) {
            this.container.classList.remove(this.toggleClassName);
            if (this.form) {
                this.form.dispatchEvent(new Event('onFormHide'));
            }
        }
        else {
            this.container.classList.add(this.toggleClassName);
        }
    };
    Search.prototype.clickContainerHandler = function (event) {
        var element = event.target;
        event.stopPropagation();
    };
    return Search;
}());
exports.Search = Search;
