"use strict";
/// <reference types="jquery"/>
/// <reference types="fancybox"/>
exports.__esModule = true;
exports.Fancybox = void 0;
require("jquery.fancybox");
require("jquery.fancybox/source/jquery.fancybox.css");
var Fancybox = /** @class */ (function () {
    function Fancybox(config) {
        this.elementsSelector = config.elementsSelector;
        if (config.options) {
            this.options = config.options;
        }
    }
    Fancybox.prototype.run = function () {
        jQuery(this.elementsSelector).fancybox(this.options);
    };
    return Fancybox;
}());
exports.Fancybox = Fancybox;
